<template>
    <b-overlay :show="loading">
        <b-card>
            <b-row>
                <b-col cols="12" class="mb-2">
                    <b-button variant="primary" v-if="allowCreate()" @click.prevent="$router.push(`/asset-peminjaman/add`)">Tambah</b-button>
                </b-col>
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select
                        id="perPageSelect"
                        v-model="perPage"
                        size="sm"
                        :options="pageOptions"
                        class="w-50"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="8" class="my-1">
                    <b-form-group
                        label="Sort"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="sortBySelect"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                        <b-form-select
                            id="sortBySelect"
                            v-model="sortBy"
                            :options="sortOptions"
                            class="w-75"
                        >
                            <template v-slot:first>
                            <option value="">-- none --</option>
                            </template>
                        </b-form-select>
                        <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
                            <option :value="false">Asc</option>
                            <option :value="true">Desc</option>
                        </b-form-select>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col md="6" class="my-1">
                    <b-form-group
                        label="Filter"
                        label-cols-sm="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="filterInput"
                        class="mb-0"
                    >
                        <b-input-group size="sm">
                        <b-form-input
                            id="filterInput"
                            v-model="filter"
                            type="search"
                            placeholder="Type to Search"
                        />
                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                        </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
        
                <b-col cols="12">
                    <b-table
                        striped
                        hover
                        responsive
                        :per-page="perPage"
                        :current-page="currentPage"
                        :items="peminjamans"
                        :fields="fields"
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection"
                        :filter="filter"
                        :filter-included-fields="filterOn"
                    >
                        <template #cell(no)="{index}">
                            <strong class="text-center">
                                {{ ++index }}
                            </strong>
                        </template>
                        <template #cell(nama)="{item}">
                            <strong v-if="item.asset">
                                <router-link :to="item.asset ? `/asset/detail/${item.asset.id}` : '/'">{{ item.asset.nama }}</router-link>
                            </strong>
                            <i class="text-danger" v-else>Aset tidak ditemukan</i>
                        </template>
                        <template #cell(peminjam)="{item}">
                            <strong v-if="item.peminjam">
                                {{ item.peminjam.nama_lengkap }}
                            </strong>
                            <i class="text-danger" v-else>Data karyawan tidak ditemukan</i>
                        </template>
                        <template #cell(actions)="{item}">
                            <b-button
                            v-b-tooltip.hover.right="'Detail'"
                                size="sm"
                                @click="$router.push(`/asset-peminjaman/detail/${item.id}`)"
                                class="mr-1"
                                variant="outline-info"
                            >
                            <feather-icon icon="EyeIcon"/>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
        
                <b-col cols="12">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                        align="center"
                        size="sm"
                        class="my-0"
                    />
                </b-col>
            </b-row>

        </b-card>
    </b-overlay>
</template>
<script>
import {BFormInput, BCard, BOverlay, VBTooltip, BRow, BCol, BPagination, BTable, BButton, BFormGroup, BInputGroup, BInputGroupAppend, BFormSelect} from 'bootstrap-vue'
export default {
    components: {
        BFormInput, BCard, BOverlay, VBTooltip, BRow, BCol, BPagination, BTable, BButton, BFormGroup, BInputGroup, BInputGroupAppend, BFormSelect
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter((f) => f.sortable)
          .map((f) => ({ text: f.label, value: f.key }));
      }
    },
    directives: {
            "b-tooltip": VBTooltip
        },
    data: () => ({
        peminjamans: [],
        loading: false,
        fields: [
            {key: 'no', label: 'No', sortable: true},
            {key: 'nama', label: 'Aset', sortable: true},
            {key: 'peminjam', label: 'Peminjam'},
            {key: 'jumlah', label: 'Jumlah', sortable: true},
            {key: 'lokasi', label: 'Lokasi'},
            {key: 'actions', label: 'Aksi'}
        ],
        perPage: 10,
        pageOptions: [10, 30, 50],
        totalRows: 1,
        currentPage: 1,
        sortBy: "",
        sortDesc: false,
        sortDirection: "desc",
        filter: null,
        filterOn: []
    }),
    methods: {
        async getPeminjaman() {
            const params = {order: 'desc'}
            this.peminjamans = await this.$store.dispatch('asset/getPeminjaman', params)
        }
    },
    async mounted() {
        this.loading = true
        await this.getPeminjaman()
        this.loading = false
    }
}
</script>